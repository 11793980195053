<template>
  <div class="modal promt-create-modal">
    <div class="overlay"  @click="$parent.closePromptCreateModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closePromptCreateModal" src="./../img/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="top">
                  <div class="title small">{{editData ? $t('Edit') : $t('Create')}}</div>
                </div>
                <div class="left">
                  <div class="select-container">
                    <select :disabled="editData" v-model="activeType">
                      <option value="" hidden disabled selected>{{$t('Type')}}</option>
                      <option value="image">{{$t('Images')}}</option>
                      <option value="doc">{{$t('Texts')}}</option>
                    </select>
                  </div>
                  <div class="select-container" v-if="categoryOptions.length">
                    <select v-model="activeCategory">
                      <option value="" hidden disabled selected>{{$t('Category')}}</option>
                      <optgroup v-if="activeType == 'image'" :label="$t('Images')">
                        <option :value="item.id" v-for="item in imagesCategoryOptions" :key="item.id">{{$t(item.title)}}</option>
                      </optgroup>
                      <optgroup v-if="activeType == 'doc'" :label="$t('Texts')">
                        <option :value="item.id" v-for="item in textCategoryOptions " :key="item.id">{{$t(item.title)}}</option>
                      </optgroup>
                    </select>
                  </div>
                  <div class="input-container">
                    <input v-model="title" :placeholder="$t('Name')"/>
                  </div>
                  <div class="input-container">
                    <textarea v-model="keywords" :placeholder="$t('Keywords (separated by spaces)')"></textarea>
                  </div>
                  <div class="input-container">
                    <textarea v-model="description" :placeholder="$t('Description')"></textarea>
                  </div>
                  <div class="input-container input-container-price">
                    <input type="number" v-model="price" :placeholder="$t('Price')"/>
                    <div class="desc">{{ $parent.currency }}</div>
                  </div>
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="confirm" v-model="confirm"/>
                        <div class="checkbox"></div>
                        <span class="title">{{$t('I confirm that my product is created by AI')}}</span>
                      </div>
                    </label>
                  </div>
                  <transition name="fade">
                    <div v-if="error" class="desc error-desc red">{{$t(error)}}</div>
                  </transition>
                  <transition name="fade">
                    <div v-if="successMessage" class="desc success-desc green">{{$t(successMessage)}}</div>
                  </transition>
                </div>
                <div class="right">
                  <div class="input-container">
                    <label :class="['img-label', {'active': image}, {'edit': editData}]">
                      <input v-if="!editData" type="file" @change="setImage" />
                      <div :class="['prompt-desc', {'active': image}]">
                        <img v-if="editData && !imageFile && activeType != 'doc'" :src='image' class="img"/>
                        <div class="doc-img" v-if="editData && !imageFile && activeType == 'doc'">
                          <img  src='./../img/doc.svg' class="img"/>
                        </div>
                        <img v-if="imageFile && imageFile.type.includes('image')"  :src='image' class="img"/>
                        <div class="doc-img" v-if="imageFile && !imageFile.type.includes('image')">
                          <img src='./../img/doc.svg' class="img"/>
                        </div>
                        <!-- <div v-if="image && imageFile" class="desc">{{$t(imageFile.name)}}</div> -->
                        <div v-if="!image" class="desc"><span>{{$t('Add your file')}}</span></div>
                      </div>
                    </label>
                  </div>
                  <div class="desc desc-click" v-if="imageFile">{{$t('Click on file to change')}}</div>
                  <div class="desc" v-if="!editData">{{$t('We only allow AI products. All products will be checked by our service')}}</div>
                </div>
                <div class="buttons">
                  <button class="button blue" @click="send">
                    <span>{{$t('CREATE OFFER')}}</span>
                  </button>
                  <button class="button" @click="$parent.closePromptCreateModal">
                    <span>{{$t('Cancel')}}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'PromtCreate',
  props: ['promptCreateModalIsVisible'],
  components: {
  },
  data: function() {
    return {
      activeCategory: '',
      activeType: '',
      title: '',
      price: '',
      keywords: '',
      description: '',
      image: null,
      imageFile: null,
      editData: null,
      categoryOptions: [],
      error: '',
      successMessage: '',
      imagesCategoryOptions: [],
      textCategoryOptions: [],
      confirm: false
    }
  },
  mounted() {
    this.getCategories();
    if (this.$parent.editData) {
      this.editData = this.$parent.editData;
    }
  },
	watch: {
		promptCreateModalIsVisible: function() {
			this.error = '';
		},
	},
  methods: {
    getCategories() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;

        let types = res.data.payload.filter(item => item.parent_id === null);
        this.imagesCategoryOptions = res.data.payload.filter(item => item.parent_id === types[0].id);
        this.textCategoryOptions = res.data.payload.filter(item => item.parent_id === types[1].id);
        
        if (this.editData) {
          const chosenCategory = this.categoryOptions.find(obj => obj.id === this.editData.category_id).id;
          this.activeCategory = chosenCategory;
          this.title = this.editData.title;
          this.price = this.editData.price;
          this.activeType = this.editData.product_type;
          this.keywords = this.editData.keywords;
          this.description = this.editData.description;
          this.image = this.$parent.imgDomain + this.editData.doc_url;
        } else {
          this.editData = null;
        }
        setTimeout(function() {
          self.$parent.clearEditData();
        }, 200)
      })
      .catch(() => {
         
      })
    },
    setImage(event) {
      const selectedFile = event.target.files[0];
      this.imageFile = selectedFile;
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = () => {
          this.image = reader.result;
        };

        reader.readAsDataURL(selectedFile);
      } else {
        this.image = null;
      }
    },
    send() {
      
      let param = {
        'media': this.imageFile,
        'category_id': this.activeCategory,
        'price': this.price,
        'title': this.title,
        'product_type': this.activeType,
        'keywords': this.keywords,
        'description': this.description,
        'confirm': this.confirm
      }
      if (this.editData) {
        this.$http.post(process.env.VUE_APP_API + 'user/media/' + this.editData.id + '/edit', param, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = 'success';
            let self = this;
            setTimeout(function() {
              self.activeCategory = '',
              self.title = '',
              self.keywords  ='',
              self.description  ='',
              self.price = '',
              self.activeType = '',
              self.image = null,
              self.imageFile = null;
              self.confirm = false;
              self.successMessage = '';
              self.$parent.closePromptCreateModal();
              self.$parent.getCreatedHistory();
            }, 2000);
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((res) => {
          if (res.response.data.errors) {
            if (res.response.data.errors.media) {
              this.error = res.response.data.errors.media[0];
            } else if (res.response.data.errors.category_id) {
              this.error = res.response.data.errors.category_id[0];
            } else if (res.response.data.errors.product_type) {
              this.error = res.response.data.errors.product_type[0];
            } else if (res.response.data.errors.title) {
              this.error = res.response.data.errors.title[0];
            } else if (res.response.data.errors.keywords) {
              this.error = res.response.data.errors.keywords[0];
            } else if (res.response.data.errors.price) {
              this.error = res.response.data.errors.price[0];
            } else if (res.response.data.errors.description) {
              this.error = res.response.data.errors.description[0];
            }  else if (res.response.data.errors.confirm) {
              this.error = res.response.data.errors.confirm[0];
            } 
          } else {
            this.error = res.response.data.message;
          }
          if(res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
      } else {
        this.$http.post(process.env.VUE_APP_API + 'offer/create', param, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          if (res.data.status == "OK") {
            this.error = '';
            this.$parent.closePromptCreateModal();
            this.$parent.openSuccessCreateModal();
            this.$parent.getCreatedHistory();
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((res) => {
          if (res.response.data.errors) {
            if (res.response.data.errors.media) {
              this.error = res.response.data.errors.media[0];
            } else if (res.response.data.errors.category_id) {
              this.error = res.response.data.errors.category_id[0];
            } else if (res.response.data.errors.product_type) {
              this.error = res.response.data.errors.product_type[0];
            } else if (res.response.data.errors.title) {
              this.error = res.response.data.errors.title[0];
            } else if (res.response.data.errors.keywords) {
              this.error = res.response.data.errors.keywords[0];
            } else if (res.response.data.errors.price) {
              this.error = res.response.data.errors.price[0];
            } else if (res.response.data.errors.description) {
              this.error = res.response.data.errors.description[0];
            } else if (res.response.data.errors.confirm) {
              this.error = res.response.data.errors.confirm[0];
            } 
          } else {
            this.error = res.response.data.message;
          }
          if(res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
      }
    }
  }
}
</script>