<template>
  <div class="modal filter-modal promt-create-modal offer-modal accept-offer">
    <div class="overlay" @click="$parent.closeAcceptOfferModal"></div>
    <div class="wrapper">
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="bottom">
                <div class="title small">{{$t('Contact information')}}</div>
                <label>
                  <input type="text" :placeholder="$t('Name')" v-model="name"/>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Surname')" v-model="surname"/>
                </label>
                <label>
                  <input type="email" :placeholder="$t('E-mail address')" v-model="email"/>
                </label>
                <label>
                  <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
                </label>
                <label>
                  <select v-model="country">
                    <option value="" disabled selected>{{$t('Country')}}</option>
                    <option v-for="(item, i) in countryOptions" :value="item" :key="i">{{$t(item.title)}}</option>
                  </select>
                </label>
                <label>
                  <input type="text" :placeholder="$t('City')" v-model="city"/>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Address')" v-model="address"/>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Post Code')" v-model="postCode"/>
                </label>
              </div>
              <div class="bottom">
                <div class="title small">{{$t('Payment detail')}}</div>
                <input type="text" pattern="\d*" maxlength="19" v-model="card_number" :placeholder="$t('Card number')"/>
                <input type="text" v-model="card_holder_name" :placeholder="$t('Card holder name')"/>
                <div class="input-row">
                    <input type="text" pattern="\d*" maxlength="2" v-model="expire_month" :placeholder="$t('Expiration date month')"/>
                    <input type="text" pattern="\d*" maxlength="4" v-model="expire_year" :placeholder="$t('Expiration date year')"/>
                </div>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms"/>
                        <div class="checkbox"></div>
                        <span class="title">{{$t('I agree with')}} </span>
                        <a @click="goToPrivacy" class="title"> {{$t('privacy policy')}}</a>
                        <span class="title"> {{$t('and')}} </span>
                        <a @click="goToTerms" class="title">{{$t('terms and conditions')}}</a>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
              <div class="buttons">
                <button :class="['button blue', {'disabled': !requiredFieldsAreFilled}]" @click="confirm">
                  <span>{{$t('Confirm')}}</span>
                </button>
                <button class="button" @click="$parent.closeAcceptOfferModal">
                  {{$t('Back')}}
                </button>
                <button class="button" @click="closeAll">
                  {{$t('Close')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'AcceptOfferModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      card_holder_name: '',
      card_number: '',
      expire_month: '',
      expire_year: '',
      email: '',
      name: '',
      surname: '',
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
    }
  },
  mounted() {
    this.getCounties();
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignIn();
      }
    })
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname && this.email && this.phone && 
        this.country && this.city && this.address  
        && this.postCode && this.terms 
        && this.card_holder_name && this.card_number 
        && this.expire_month && this.expire_year 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    closeAll() {
      this.$parent.closeOfferModal();
      this.$parent.closeAcceptOfferModal();
    },
    confirm() {
      let data = {
        'id': this.$parent.activeOffer.id,
        'card_number': this.card_number,
        'card_holder_name': this.card_holder_name,
        'expire_month': this.expire_month,
        'expire_year': this.expire_year,
        'name': this.name,
        'surname': this.surname,
        'email': this.email,
        'phone': this.phone,
        'country': this.country.title,
        'city': this.city,
        'address': this.address,
        'postCode': this.postCode
      }
      this.$http.post(process.env.VUE_APP_API + 'offer/withdraw/card', data)
      .then(() => {
        this.$parent.closeAcceptOfferModal();
        this.$parent.closeOfferModal();
        this.$parent.openDealModal();
        this.$parent.getCreatedHistory();
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.$emit('setError', firstErrors[key]);
            }
          }
        } else {
          this.$emit('setError', res.response.data.message);
        }
      })
    },
    goToTerms() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPage('terms');
    },
    goToPrivacy() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPage('privacy');
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
  }
}
</script>